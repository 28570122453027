<template>
  <div v-if="waybill" class="row">
    <modal :adaptive="true" height="auto" name="waybill-transaction-history" width="1000">
      <waybill-transition-history-modal :transaction-type="transactionType"
        :waybill-transaction-id="waybillTransactionId"></waybill-transition-history-modal>
    </modal>

    <div class="col-md-12 waybill-detail-master">

      <dashboard-box :title="$t('waybill.waybill_detail')">
        <template v-slot:preview>
          <div class="row px-5 py-3">
            <div class="col-lg-12 col-md-12 col-sm-12 d-lg-none d-md-none d-sm-block mb-4 px-0">
              <div class="d-flex col-lg-12 justify-content-start">
                <q-r-code :class-name="'mr-2'" :code="waybill.code" :value="waybill.code_link" level="H" size="75">
                </q-r-code>
                <img :src="waybill.waybill_file" alt="" class="w-75px rounded-lg mx-2">
              </div>
            </div>
            <div class="row w-100">
              <div class="col-5"><span class="font-size-sm font-weight-bold"><b>{{ waybill.transaction_type ?
                  $t('waybill.depo_entry_date') : $t('waybill.depo_exit_date')
              }}:</b></span></div>
              <div class="col-7"><span class="font-size-sm">{{ waybill.transaction_date | momentDateFormat }}</span>
              </div>
              <div class="col-5"><span class="font-size-sm font-weight-bold"><b>{{ $t('waybill.company') }}:</b></span>
              </div>
              <div class="col-7"><span class="font-size-sm">{{ waybill.supplier_company == null ? '-' :
                  waybill.supplier_company.name
              }}</span></div>
              <div class="col-5"><span class="font-size-sm font-weight-bold"><b>{{ $t('waybill.document_no')
              }}:</b></span></div>
              <div class="col-7"><span class="font-size-sm">{{ waybill.document_number }}</span></div>
            </div>
            <div class="row w-100">
              <div class="col-5">
                <span class="font-size-sm font-weight-bold"><b>{{ $t('waybill.waybill_type') }}:</b> </span>
              </div>
              <div class="col-7">
                <span class="font-size-sm">{{ waybill.waybill_type.translations[0].name }}</span>
              </div>
              <div class="col-5">
                <span class="font-size-sm font-weight-bold"><b>{{ $t('waybill.waybill_date') }}:</b> </span>
              </div>
              <div class="col-7">
                <span class="font-size-sm">{{ waybill.waybill_date | momentDateFormat }}</span>
              </div>
              <div class="col-4">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.created_user') }}</p>
              </div>
              <div class="col-8">
                <p class="font-size-lg">{{ waybill.created_user?waybill.created_user.name:'' }}</p>
              </div>

            </div>
            <hr>
            <div class="col-12 my-7" style="border: 1px solid rgba(150, 150, 150, 0.2);"></div>
            <div v-for="(item, index) in waybill.transactions" :key="index" class="col-12">
              <div class="row mb-5">
                <div class="col-12">
                  <span class="bold h3"><u>{{ index + 1 }}. {{ $t('waybill.line') }} </u></span>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.order_no') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.waybillable.order_number }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.product_type') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.instruction_type.translations[0].name }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.product_name') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.product_name }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.depo_type') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.store.translations[0].name }} </p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.net_amount') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ parseInt(item.net_amount) }} {{ item.unit.code }}</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.tare_amount') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ parseInt(item.tare_amount) }} {{ item.unit.code }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.gross_amount') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ parseInt(item.gross_amount) }} {{ item.unit.code }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.number_of_roll') }}:</b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ parseInt(item.fabric_roll) }}</p>
                    </div>
                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.part_number') }}: </b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.part_number }}</p>
                    </div>

                    <div class="col-4">
                      <p class="font-size-lg"><b>{{ $t('waybill.description') }}: </b></p>
                    </div>
                    <div class="col-8">
                      <p class="font-size-lg">{{ item.description || '-' }}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row d-flex justify-content-center content-between mt-2 mb-5 px-3">
                <span
                  class="px-3 col-7 mt-2 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm navy-blue-border-1px text-navy-blue"
                  @click="showPreviousEntryTransitionHistory(item)">
                  <span v-html="getIconByKey('icons.waybill.history', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                  })">
                  </span>
                  {{ $t('waybill.entry_transition_history').toUpperCase() }}
                </span>

                <span
                  class="px-3 col-7 mt-2 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm navy-blue-border-1px text-navy-blue">
                  <a :href="`${item.waybill_tag_pdf_link}`"
                    class="px-3 py-1  d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
                    target="_blank">
                    <span v-html="getIconByKey('icons.waybill.press_tag', {
                      class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                    })">
                    </span>
                    {{ $t('waybill.print_label').toUpperCase() }}
                  </a>
                </span>
                <span
                  class="px-3 col-7 mt-2 py-0 navy-blue-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm navy-blue-border-1px text-navy-blue"
                  @click="showPreviousExitTransitionHistory(item)">
                  <span v-html="getIconByKey('icons.waybill.history', {
                    class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                  
                  })">
                  </span>
                  {{ $t('waybill.exit_transition_history').toUpperCase() }}
                </span>
              </div>
              <hr>
            </div>

            <div class="col-6 py-2" v-for="(item, index) in waybill.description">
              <div class="col-4">
                <p class="font-size-lg font-weight-bold">{{ $t('waybill.general_description') }} {{ ++index }} : </p>
              </div>
              <div class="col-8">
                <p class="font-size-lg">{{ item }}</p>
              </div>
            </div>

            <div class="col-12 d-flex justify-content-center content-between mt-2 mb-2 px-0 ">
              <span v-if="isUserGranted('Waybill', ['update'], false)"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="$router.push({ name: Number(waybill.transaction_type) === 0 ? 'waybill.exit.edit' : 'waybill.entry.edit', params: { id: waybill.id } })">
                <span v-html="getIconByKey('icons.waybill.edit_2', {
                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                })">
                </span>
                {{ $t('waybill.edit').toUpperCase() }}
              </span>
              <span v-if="isUserGranted('Waybill', ['delete'], false)"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center cursor-pointer font-size-sm mr-2"
                @click="deleteWaybill">
                <span v-html="getIconByKey('icons.waybill.delete_2', {
                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                })">
                </span>
                {{ $t('waybill.delete_waybill').toUpperCase() }}
              </span>
            </div>
            <div class="col-12 d-flex justify-content-center content-between mt-2 mb-5 px-0 pb-5">
              <a :href="`${waybill.waybill_pdf_link}`"
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue"
                target="_blank">
                <span v-html="getIconByKey('icons.waybill.pdf', {
                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                })">
                </span>
                {{ $t('waybill.create_pdf').toUpperCase() }}
              </a>
              <span
                class="px-3 py-1 green-border-1px rounded-full d-flex justify-content-center items-center mx-2 cursor-pointer text-navy-blue">
                <span v-html="getIconByKey('icons.waybill.excel', {
                  class: 'w-20px h-20px object-cover d-inline-block opacity-75',
                })">
                </span>
                {{ $t('waybill.create_excel').toUpperCase() }}
              </span>

            </div>
          </div>
        </template>
      </dashboard-box>
    </div>
  </div>
</template>

<script>
import DashboardBox from "@/assets/components/DashboardBox";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters, mapMutations } from 'vuex'
import WaybillTransitionHistoryModal from "@/view/pages/waybill/detail/layouts/WaybillTransitionHistoryModal";
import { DELETE_ITEM_BY_ID, GET_ITEM_DETAIL_BY_ID } from "@/core/services/store/REST.module";
import moment from "moment";
import { LARAVEL_DATE_FORMAT, LARAVEL_DATE_TIME_FORMAT } from "@/core/config/constant";
import QRCode from "@/assets/components/widget/QRCode";

export default {
  name: "WaybillDetail",
  components: {
    QRCode,
    DashboardBox,
    WaybillTransitionHistoryModal
  },
  computed: {
    ...mapGetters({}),
    amount_unit() {
      return this.waybill && this.waybill.unit.translations[0].name || '-'
    }
  },
  data() {
    return {
      waybill: null,
      waybillTransactionId: null,
      transactionType: null,
    };
  },
  methods: {
    ...mapMutations({}),
    deleteWaybill() {
      let self = this;
      this.sweetAlertConfirm(this.$t('general.are_you_sure')).then(result => {
        if (result) {
          self.sweetAlertLoading();
          self.$store.dispatch(DELETE_ITEM_BY_ID, {
            url: `api/waybills/${this.$route.params.id}`,
            id: this.$route.params.id,
          }).then(response => {
            if (response.status) {
              self.sweetAlertSuccess(self.$t('general.successfully_deleted')).then(() => {
                self.$router.go(-1);
              })

            } else {
              self.sweetAlertError(self.$t('general.internal_error'));
            }
          })
        }
      })
    },
    showPreviousEntryTransitionHistory(item) {
      this.waybillTransactionId = item.id;
      this.transactionType = 1;
      this.$modal.show('waybill-transaction-history');
    },
    showPreviousExitTransitionHistory(item) {
      this.waybillTransactionId = item.id;
      this.transactionType = 0;
      this.$modal.show('waybill-transaction-history');
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('waybill.waybill'), route: "waybill.index" },
      { title: this.$t('waybill.waybill_detail') }
    ]);

    let self = this;

    if (this.isUserGranted('Waybill', ['view'])) {
      this.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: `api/waybills/${this.$route.params.id}`,
        acceptPromise: true,
      }).then((result) => {
        if (result.status) {
          self.waybill = result.data;
          self.waybill.description = JSON.parse(self.waybill.description);
          self.waybill.created_at = moment(self.waybill.created_at, LARAVEL_DATE_TIME_FORMAT)
          self.waybill.waybill_date = moment(self.waybill.waybill_date, LARAVEL_DATE_FORMAT)
          self.waybill.transaction_date = moment(self.waybill.transaction_date, LARAVEL_DATE_FORMAT)
        } else {
          self.sweetAlertError(self.$t('general.invalid_request')).then(() => {
            self.$router.go(-1);
          })
        }
      })
    }
  }
}
</script>

<style>
.waybill-detail-master {
  background-color: #f3f6f9 !important;
}
</style>
